import React from "react";
import { LoadingSpinner } from "../components/atoms/LoadingSpinner";

export default function Loading() {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-base-100">
      <LoadingSpinner className="w-9 h-9 animate-spin fill-primary" />
    </div>
  );
}
