"use client";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { Roboto } from "next/font/google";
import React, { createContext, useContext, useEffect, useState } from "react";
import { themeChange } from "theme-change";
import { blueGrey } from "@mui/material/colors";

const roboto = Roboto({
  weight: ["400", "700"],
  style: ["normal"],
  subsets: ["latin"],
});

const lightTheme = createTheme({
  typography: {
    fontFamily: roboto.style.fontFamily,
  },
  // match breakpoints with tailwindcss
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    primary: {
      main: "#D66727",
    },
    secondary: {
      main: "#777777",
    },
    grey: {
      300: "#E0E0E0",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "uppercase",
          fontWeight: "normal",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ _theme }) => ({
          "&.Mui-selected": {
            backgroundColor: blueGrey[50] + " !important",
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {},
    },
    MuiSelect: {
      styleOverrides: {},
      defaultProps: {
        variant: "outlined",
        MenuProps: {
          disableScrollLock: true,
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
          transitionDuration: 0,
        },
      },
    },
  },
});

const darkTheme = createTheme({
  ...lightTheme,
  palette: {
    mode: "dark",
    primary: {
      main: "#D66727",
    },
    secondary: {
      main: "#777777",
    },
  },
  components: {},
});

// Create a context for the theme
export const ThemeContext = createContext<{
  theme: string | null;
  handleThemeChange: () => void;
}>({
  theme: null,
  handleThemeChange: () => {},
});

export const useTheme = () => {
  return useContext(ThemeContext);
};

const ThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState<null | string>(null);

  useEffect(() => {
    themeChange(false);
    if (localStorage.getItem("themeMode") === "dark") {
      setThemeMode("dark");
    } else {
      localStorage.setItem("themeMode", "light");
      setThemeMode("light");
    }
  }, []);

  const handleThemeChange = () => {
    themeChange(false);
    const newThemeMode = themeMode === "dark" ? "light" : "dark";
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("themeMode", newThemeMode);
    }
    setThemeMode(newThemeMode);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme: themeMode,
        handleThemeChange,
      }}
    >
      <MuiThemeProvider theme={themeMode === "dark" ? darkTheme : lightTheme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
