import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { initialToastState } from "@contexts/ToastProvider";

export type TToastState = {
  isOpen: boolean;
  message: string;
  isSuccess: boolean;
};

export const Toast = ({
  toastState,
  setToastState,
}: {
  toastState: TToastState;
  setToastState: (toastState: TToastState) => void;
}): React.ReactNode => {
  const { isOpen, message, isSuccess } = toastState;

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setToastState(initialToastState);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
    >
      <Alert
        onClose={handleClose}
        severity={isSuccess ? "success" : "error"}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
