"use client";
import { createContext, useContext, useEffect, useState } from "react";
import { TToastState, Toast } from "@components/molecules/Toast";

const ToastContext = createContext({
  toastState: {
    message: "",
    isSuccess: true,
    isOpen: false,
  },
  setToastState: (_toastState: TToastState) => {},
});

export const initialToastState: TToastState = {
  message: "",
  isSuccess: true,
  isOpen: false,
};

export const ToastProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const [toastState, setToastState] = useState(initialToastState);

  useEffect(() => {
    let toastTimeout: NodeJS.Timeout;

    if (toastState.isOpen) {
      toastTimeout = setTimeout(() => {
        setToastState(initialToastState);
      }, 3000);
    }

    return () => {
      clearTimeout(toastTimeout);
    };
  }, [toastState]);

  return (
    <ToastContext.Provider value={{ toastState, setToastState }}>
      {children}
      <Toast toastState={toastState} setToastState={setToastState} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
