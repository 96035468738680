import Image from "next/image";
import Link from "next/link";

export const Footer = ({
  className = "",
}: {
  className?: string;
}): React.ReactNode => {
  return (
    <footer className={`${className} flex flex-col items-center`}>
      <div className="my-2">
        <Link href="/" className="flex items-center gap-2">
          <Image
            src="/logo_e.svg"
            width="28"
            height="32"
            alt="Eunice logo"
            priority={true}
            placeholder="empty"
          />
          <Image
            src="/logo_type.svg"
            width="70"
            height="18"
            className="dark:invert"
            alt="Eunice logo"
            priority={true}
            placeholder="empty"
          />
        </Link>
      </div>
      <span>Copyright © Reasoon Ltd. (trading as Eunice)</span>
    </footer>
  );
};
