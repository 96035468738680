"use client";

import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import React from "react";

const ProgressBarProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  return (
    <>
      {children}
      <ProgressBar
        height="2px"
        color="#d66727b3"
        options={{ showSpinner: false }}
        shallowRouting
        style={`
        #nprogress .bar {
            background: #d66727b3;
            position: fixed;
            z-index: 1350 !important;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
        }`}
      />
    </>
  );
};
export default ProgressBarProvider;
